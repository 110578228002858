import React, { useState } from "react";
import { generateDate, months } from "../components/Book/calendar";
import cn from "../components/Book/cn";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { useGlobal } from "../components/Context/Context";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../components/firebase/firebase";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function ChooseDate() {
  const { selectedTime, setSelectedTime, currentDate } = useGlobal();
  const days = ["S", "M", "T", "W", "T", "F", "S"];
  const [today, setToday] = useState(currentDate);
  const { selectedDate, setSelectedDate } = useGlobal();
  const [scheduleTime, setScheduleTime] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    const timeRef = collection(
      db,
      "bookDetails",
      selectedDate.toDate().toDateString(),
      "bookTime"
    );
    const unsub = onSnapshot(timeRef, (snapshot) => {
      const schedule = [
        { time: "06:00 AM - 12:35 PM", slot: "10" },
        { time: "06:30 AM - 01:05 PM", slot: "10" },
        { time: "07:00 AM - 01:35 PM", slot: "10" },
        { time: "07:30 AM - 02:05 PM", slot: "10" },
        { time: "08:00 AM - 02:35 PM", slot: "10" },
        { time: "08:30 AM - 03:05 PM", slot: "10" },
        { time: "09:00 AM - 03:35 PM", slot: "10" },
        { time: "09:30 AM - 04:05 PM", slot: "10" },
        { time: "10:00 AM - 04:35 PM", slot: "10" },
      ];
      snapshot.forEach((doc) => {
        const { time, visitorsNumber } = doc.data();
        if (visitorsNumber >= 10) {
          const index = schedule.findIndex((index) => index.time === time);
          if (index > -1) {
            schedule.splice(index, 1);
          }
        } else {
          const index = schedule.findIndex((index) => index.time === time);
          schedule[index].slot = 10 - visitorsNumber;
        }
      });
      setScheduleTime(schedule);
      setIsLoading(false);
    });
    return () => unsub();
  }, [selectedDate]);

  return (
    <div className="flex py-5 gap-5 items-center lg:divide-x sm:w-1/2 justify-center flex-col mx-auto h-full md:h-screen">
      <h1 className="text-green-500 text-2xl">Book for a schedule</h1>
      <div className="flex gap-10 items-center lg:flex-row flex-col">
        <div className="sm:w-96 sm:h-96 max-w-[270px]">
          <div className="flex justify-between items-center">
            <h1 className="select-none font-semibold">
              {months[today.month()]}, {today.year()}
            </h1>
            <div className="flex gap-10 items-center ">
              <GrFormPrevious
                className="w-5 h-5 cursor-pointer hover:scale-105 transition-all"
                onClick={() => {
                  setToday(today.month(today.month() - 1));
                }}
              />
              <h1
                className=" cursor-pointer hover:scale-105 transition-all"
                onClick={() => {
                  setToday(currentDate);
                }}
              >
                Today
              </h1>
              <GrFormNext
                className="w-5 h-5 cursor-pointer hover:scale-105 transition-all"
                onClick={() => {
                  setToday(today.month(today.month() + 1));
                }}
              />
            </div>
          </div>
          <div className="grid grid-cols-7 ">
            {days.map((day, index) => {
              return (
                <h1
                  key={index}
                  className="text-sm text-center h-14 w-14 grid place-content-center text-gray-500 select-none"
                >
                  {day}
                </h1>
              );
            })}
          </div>

          <div className=" grid grid-cols-7 ">
            {generateDate(today.month(), today.year()).map(
              ({ date, today }, index) => {
                return (
                  <div
                    key={index}
                    className="p-2 text-center h-14 grid place-content-center text-sm border-t"
                  >
                    {/*Green/gray color dates available, manage here */}
                    <h1
                      className={cn(
                        (date.day() > 4 ) &&                             //Greater than 4 is Friday onwards up to Saturday
                          date.diff(currentDate, "day") + 1 >= 2 &&       //at least 2 days advance
                          date.diff(currentDate, "day") + 1 <= 30        //Up to 1 month max
                          ? // Start here
                            // (( date.date() !== 23 && date.date() !== 24 && date.month() !== 8) || //block Aug 23-24, 2024
                            // ( date.month() === 8 && date.year() === 2024)) &&
                            (( date.date() !== 25 && date.date() !== 26 && date.month() !== 10) || //block Oct 25-26, 2024
                            ( date.month() === 10 && date.year() === 2024)) &&
                            (( date.date() !== 1 && date.date() !== 2 && date.month() !== 11) || //block Nov 1-2, 2024
                            ( date.month() === 11 && date.year() === 2024)) &&
                            (( date.date() !== 27 && date.date() !== 28 && date.month() !== 12) || //block Dec 27-28, 2024
                            ( date.month() === 12 && date.year() === 2024)) &&
                            (( date.date() !== 3 && date.date() !== 4 && date.month() !== 1) || //block Jan 3-4, 2025
                            ( date.month() === 1 && date.year() === 2025)) 
                            

                             //add dates to block here

                            ? "text-green-500 font-bold"
                            : "text-gray-400"
                          : "text-gray-400",
                        today ? "bg-blue-600 text-white" : "",

                        selectedDate.toDate().toDateString() ===
                          date.toDate().toDateString()
                          ? "bg-black text-white"
                          : "",
                        "h-10 w-10 rounded-full grid place-content-center hover:bg-black transition-all cursor-pointer select-none"
                      )}
                      onClick={() => {
                        setSelectedDate(date);
                        setSelectedTime("");
                      }}
                    >
                      {date.date()}
                    </h1>
                  </div>
                );
              }
            )}
          </div>
        </div>
        <div className="h-96 w-96 sm:px-5 max-w-[280px]">
          <h1 className=" font-semibold">
            Available Schedule for {selectedDate.toDate().toDateString()}    
          </h1>
          <div className="flex flex-col gap-2 my-3">
            {/*0 for Sunday  1 for monday 2 for tuesday */}   
            {selectedDate.day() === 1 || selectedDate.day() === 2 || selectedDate.day() === 0 || selectedDate.day() === 3 || selectedDate.day() === 4? (
              <div>Sorry. No booking from Sunday to Thursday</div>
            ) : selectedDate.diff(currentDate, "day") + 1 >= 2 &&
              selectedDate.diff(currentDate, "day") + 1 <= 30 ? (
              // Add unavailable dates here
              // Start here
              // ((selectedDate.date() !== 23 && selectedDate.date() !== 24 && selectedDate.month() !== 8) ||      //block Aug 23-24, 2024
              // (selectedDate.month() === 8 && selectedDate.year() === 2024)) &&
              ((selectedDate.date() !== 25 && selectedDate.date() !== 26 && selectedDate.month() !== 10) ||      //block Oct 25-26, 2024
              (selectedDate.month() === 10 && selectedDate.year() === 2024)) &&
              ((selectedDate.date() !== 1 && selectedDate.date() !== 2 && selectedDate.month() !== 11) ||      //block Nov 1-2, 2024
              (selectedDate.month() === 11 && selectedDate.year() === 2024)) &&
              ((selectedDate.date() !== 27 && selectedDate.date() !== 28 && selectedDate.month() !== 12) ||      //block Dec 27-28, 2024
              (selectedDate.month() === 12 && selectedDate.year() === 2024)) &&
              ((selectedDate.date() !== 3 && selectedDate.date() !== 4 && selectedDate.month() !== 1) ||      //block Jan 3-4, 2025
              (selectedDate.month() === 1 && selectedDate.year() === 2025))
              ? (
              //Until Here
                !isLoading ? (
                  scheduleTime.map((index) => {
                    return (
                      <div key={index.time} className="flex flex-row gap-5">
                        <div
                          onChange={(e) => {
                            setSelectedTime(e.target.value);
                          }}
                        >
                          <input type="radio" value={index.time} name="time" />
                          <span className="pl-2">{index.time}</span>
                        </div>
                        <p>{index.slot} slot</p>
                      </div>
                    );
                  })
                ) : (
                  <div>Please wait...</div>
                )
              ) : (
                <div>No booking avaible</div>
              )
            ) : (
              <div>
                Sorry. Booking can be made at least 2 days from now and up to 1 month
                only.
              </div>
            )}
          </div>
          <button
            onClick={() => navigate("/input_information")}
            disabled={!selectedTime}
            className={`${
              !selectedTime
                ? "bg-gray-100 text-gray-900 hover:bg-gray-200 cursor-not-allowed"
                : "bg-blue-100 text-blue-900 hover:bg-blue-200"
            } inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2`}
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
}
